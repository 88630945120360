import Vue from 'vue'
import Router from 'vue-router'
import Panel from '../layouts/Panel.vue'
import index from '../page/index.vue'
import download from '../page/download.vue'

Vue.use(Router)

export default new Router({
  // mode: 'history',
  routes: [{
    // 用于微信apk下载
    path: '/download',
    name: 'download',
    component: download
  }, {
    path: '/',
    name: 'index',
    redirect: '/',
    component: Panel,
    children: [{
      path: '/',
      name: 'home',
      component: () => import('@/page/index')
    },{
      path: '/work',
      name: 'work',
      component: () => import('@/page/work')
    },{
      path: '/product',
      name: 'product',
      component: () => import('@/page/product')
    },{
      path: '/down',
      name: 'down',
      component: () => import('@/page/down')
    },{
      path: '/video',
      name: 'video',
      component: () => import('@/page/video')
    },{
      path: '/about',
      name: 'about',
      component: () => import('@/page/about')
    }]
  }]
})

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}