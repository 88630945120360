import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios-jsonp-pro'
import VueAxios from 'vue-axios'
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

Vue.use(VueAwesomeSwiper)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false;

let loading = null
axios.defaults.timeout = 30000

axios.interceptors.request.use(config => {
  return config
}, err => {
  return Promise.reject(err)
})

axios.interceptors.response.use(
  response => {
    if (loading) {
      loading.close()
    }

    return response
  },
  error => {
    if (loading) {
      loading.close()
    }
      if (error.response) {
          window.alert('其他错误')
      } else {
      }
  }
)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
