<template>
  <div id="app">
    <!-- <Login msg="Welcome to Your Vue.js App"/> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import Login from './components/login.vue'

export default {
  name: 'app',
  components: {
    // Login
    
  }
}
</script>

<style>
  #page-container > #page-header .content-header,
  #page-container > #page-header .content,
  #page-container > #main-container .content,
  #page-container > #page-footer .content {
    max-width: 100% ;
  }
</style>
