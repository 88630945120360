<template>
  <div
    id="page-container"
    class="sidebar-light side-scroll page-header-fixed page-header-light"
  >
    <!-- Sidebar -->
    <nav id="sidebar" aria-label="Main Navigation">
      <!-- Side Header -->
      <div class="content-header bg-light">
        <!-- Logo -->
        <a
          class="text-dual d-inline-block font-w600"
          @click.prevent="custormAnchor('index')"
        >
          <img :src="logo" width="105" height="50" />
        </a>
        <!-- END Logo -->

        <!-- Options -->
        <div>
          <!-- Close Sidebar, Visible only on mobile screens -->
          <a
            class="d-lg-none text-dark ml-2"
            data-toggle="layout"
            data-action="sidebar_close"
            href="javascript:void(0)"
          >
            <i class="fa fa-times-circle"></i>
          </a>
          <!-- END Close Sidebar -->
        </div>
        <!-- END Options -->
      </div>
      <!-- END Side Header -->

      <!-- Side Navigation -->
      <div class="content-side content-side-full">
        <ul class="nav-main">
          <li
            class="nav-main-item"
            v-for="(item, index) in titles"
            :key="index"
          >
            <a
              class="nav-main-link"
              @click.prevent="custormAnchor(item.nav)"
              v-if="!item.subItem || item.subItem.length === 0"
            >
              <span class="nav-main-link-name">{{ item.name }}</span>
            </a>
            <a
              v-else
              class="nav-main-link nav-main-link-submenu"
              data-toggle="submenu"
              aria-haspopup="true"
              aria-expanded="false"
              href="#"
            >
              <span class="nav-main-link-name">{{ item.name }}</span>
            </a>
            <ul
              class="nav-main-submenu"
              v-for="(itm, inx) in item.subItem"
              :key="inx"
            >
              <li class="nav-main-item">
                <a
                  class="nav-main-link"
                  @click.prevent="custormAnchor(itm.nav)"
                >
                  <span class="nav-main-link-name">{{ itm.name }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- END Side Navigation -->
    </nav>
    <!-- END Sidebar -->

    <!-- Header -->
    <header id="page-header">
      <!-- Header Content -->
      <div
        :class="
          isMb ? 'content-header' : 'content-header content-header-center'
        "
      >
        <!-- Left Section -->
        <div class="d-flex align-items-center">
          <!-- Logo -->
          <a class="text-dual" @click.prevent="custormAnchor('index')">
            <img :src="logo" width="105" height="50" />
          </a>
          <!-- END Logo -->

          <!-- Menu -->
          <ul
            class="
              nav-main nav-main-horizontal nav-main-hover
              d-none d-lg-block
              ml-4
            "
          >
            <li
              class="nav-main-item"
              v-for="(item, index) in titles"
              :key="index"
            >
              <a
                class="nav-main-link"
                @click.prevent="redirctGoTo(item.nav)"
                v-if="item.redirct"
              >
                <span class="nav-main-link-name">{{ item.name }}</span>
              </a>
              <a
                class="nav-main-link"
                @click.prevent="custormAnchor(item.nav)"
                v-else-if="!item.subItem || item.subItem.length === 0"
              >
                <span class="nav-main-link-name">{{ item.name }}</span>
              </a>
              <a
                v-else
                class="nav-main-link nav-main-link-submenu"
                data-toggle="submenu"
                aria-haspopup="true"
                aria-expanded="false"
                href="#"
              >
                <span class="nav-main-link-name">{{ item.name }}</span>
              </a>
              <ul class="nav-main-submenu" v-if="item.subItem">
                <li
                  class="nav-main-item"
                  v-for="(itm, inx) in item.subItem"
                  :key="inx"
                >
                  <a
                    class="nav-main-link"
                    @click.prevent="custormAnchor(itm.nav)"
                  >
                    <span class="nav-main-link-name">{{ itm.name }}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <!-- END Menu -->
        </div>
        <!-- END Left Section -->

        <!-- Right Section -->
        <div>
          <!-- Toggle Sidebar -->
          <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
          <button
            type="button"
            class="btn btn-dual d-lg-none ml-1"
            data-toggle="layout"
            data-action="sidebar_toggle"
          >
            <i class="fa fa-fw fa-bars"></i>
          </button>
          <!-- END Toggle Sidebar -->
        </div>
        <!-- END Right Section -->
      </div>
      <!-- END Header Content -->

      <!-- Header Loader -->
      <!-- Please check out the Loaders page under Components category to see examples of showing/hiding it -->
      <div id="page-header-loader" class="overlay-header bg-primary-darker">
        <div class="content-header">
          <div class="w-100 text-center">
            <i class="fa fa-fw fa-2x fa-sun fa-spin text-white"></i>
          </div>
        </div>
      </div>
      <!-- END Header Loader -->
    </header>
    <!-- END Header -->

    <!-- Main Container -->
    <main id="main-container">
      <!-- Page Content -->
      <router-view :key="$route.fullPath"></router-view>
      <!-- END Page Content -->
    </main>
    <!-- END Main Container -->

    <!-- Footer -->
    <footer id="page-footer" class="bg-dark">
      <div class="content py-0">
        <div class="row font-size-sm">
          <div
            class="col-sm-6 order-sm-2 mb-1 mb-sm-0 text-center text-sm-right"
          >
          <span class="text-secondary">ICP证：</span>
            <a
              class="text-light"
              href="https://beian.miit.gov.cn/"
              target="_blank"
              >{{ version }}</a
            >                  
          </div>
          <div
            class="col-sm-6 order-sm-1 text-center text-sm-left text-secondary"
          >
            <a class="font-w600 text-secondary" href="#">{{ copy }}</a>
            <span class="text-secondary">&copy;</span>
            <span class="text-secondary" data-toggle="year-copy"></span>
          </div>
        </div>
      </div>
    </footer>
    <!-- END Footer -->
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {
      logo: "https://oss.cnwscada.com/officalWebsite/assets/image/logo.png",
      copy: "库软科技 版权所有",
      version: "沪ICP备15030820号-1",
      titles: [
        {
          nav: "index",
          name: "首页",
        },
        {
          nav: "work",
          name: "解决方案与案例",
        },
        {
          nav: "product",
          name: "产品中心",
        },
        {
          nav: "down",
          name: "下载中心",
        },
         {
          nav: "http://kj5c.web.cnwscada.com:9090/",
          name: "云展厅",
          redirct: true
        },
        {
          nav: "video",
          name: "视频中心",
        },
        {
          nav: "especially",
          name: "高级应用",
          subItem: [
            // {
            //   nav: "http://47.112.247.65/demo/html/login.html?id=admin",
            //   name: "物联网轻平台",
            // },
            // {
            //   nav: "http://mb22.web.cnwscada.com:9090/",
            //   name: "组态框架模板",
            // },
            {
              nav: "http://121.42.203.154:8081",
              name: "酷炫大屏组态",
            },
            // {
            //   nav: "http://mb22data.web.cnwscada.com:9090/",
            //   name: "H5 web发布DEMO",
            // },
            // {
            //   nav: "http://kj5c.web.cnwscada.com:9090/",
            //   name: "云盒子纯WEB发布",
            // },
            // {
            //   nav: "http://ks22wj0421.web.cnwscada.com:9090/",
            //   name: "云屏纯WEB发布",
            // },
            {
              nav: "https://project.cnwscada.com",
              name: "组态工程分享平台",
            },
            {
              nav: "http://121.42.203.154:8086/login",
              name: "安卓APP定制平台",
            },
            {
              nav: "https://www.cnwscada.com/inteldevice_admin/login",
              name: "GIS公众号平台",
            },
            {
              nav: "https://xd.cnwscada.com/auth/login",
              name: "小度AI后台管理",
            }, {
              nav: "https://alarm.cnwscada.com",
              name: "综合告警平台",
            },
            // {
            //   nav: "http://47.112.247.65/bank",
            //   name: "环境监控平台DEMO",
            // }
          ],
        },
        {
          nav: "about",
          name: "联系我们",
        },
      ],
    };
  },
  computed: {
    isMb() {
      return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    },
  },
  mounted() {},
  methods: {
    custormAnchor(index) {
      var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
      var objExp = new RegExp(Expression);
      if (objExp.test(index)) {
        window.open(index);
      } else {
        if (this.isMb) {
          Dashmix.layout("sidebar_close");
        }
        this.$router.push({
          name: index,
        });
        this.$forceUpdate();
      }
    },
    redirctGoTo(nav) {
      window.open(nav, "_blank");
    }
  },
};
</script>

<style scoped>
a {
  color: #98c593;
}

h2 {
  font-size: 2.25rem;
}

a:hover {
  cursor: pointer;
}

.nav-main-link-name {
  color: #000000;
  padding-left: 1rem;
  padding-right: 1rem;
}
.content-header-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
