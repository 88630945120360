<template>
      <div class="bg-white">
        <div class="content content-full">
            <div class="row" v-for="(item, index) in indexImageList" :key="index">
            <div class="col-md-12 col-12" :id="item.name">
              <div class="title">{{item.title}}</div>
              <div
                class="
                  block block-rounded block-bordered block-mode-loading-refresh
                "
              >
                <img :src="item.url" style="height: 100%; width: 100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {
      indexImageList: [
        {
          name: "index",
          title: '随时随地 触屏可控',
          url: "https://oss.cnwscada.com/officalWebsite/assets/image/intro.jpg",
        },
        {
          name: "soft",
          title: '库软组态 VS 其它组态',
          url: "https://oss.cnwscada.com/officalWebsite/assets/image/one.jpg",
        },
        {
          name: "principle",
          title: '应用原理图',
          url: "https://oss.cnwscada.com/officalWebsite/assets/image/two.jpg",
        },
      ],
    };
  }
};
</script>

<style scoped>
a {
  color: #98c593;
}

h2 {
  font-size: 2.25rem;
}

a:hover {
  cursor: pointer;
}

.nav-main-link-name {
  color: #000000;
}
.title {
  font-size: 28px;
  line-height:120%;
  padding-bottom: 1.5rem;
  text-align: center;
  padding-top: 2.5rem;
}
</style>
