<template>
  <div class="containerInfo">
    <div class="dl-step">
      <div class="step dl-step-1">
        <div class="index">1</div>
        <div class="contentInfo">点击右上方</div>
      </div>
      <div class="step dl-step-2">
        <div class="index">2</div>
        <div class="contentInfo">
          <p class="tip">选择在浏览器中打开</p>
        </div>
      </div>
      <div class="step dl-step-3">
        <div class="index">3</div>
        <div class="contentInfo">
          <p class="tip">
            页面会弹出确认框,点击确认下载app,如果没有提示,请刷新重试
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPC: false,
      iswx: false,
      url: "",
    };
  },
  methods: {
    init() {
      var e = navigator.userAgent ? navigator.userAgent.toLowerCase() : "";
      this.iswx = /micromessenger/.test(e) ? !0 : !1;
      console.log(this.iswx, "isWx")
      const url = this.$route.query.url;
      if (url == null || url.length < 1) {
        this.url = "";
        return;
      }
      this.url = url;
      if (!this.isPC && !this.iswx) {
        if (this.url) {
          window.location.href = decodeURIComponent(this.url);
        }
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.init();
      });
    }, 200);
  },
};
</script>

<style type="text/css">
body {
  height: 100%;
}

.containerInfo {
  padding: 0 20px;
  background-position: center center;
  background-size: 100% 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2c3e50;
}

.dl-step {
  padding-top: 58px;
}

.dl-step .step {
  clear: both;
}

.dl-step .index {
  float: left;
  height: 36px;
  width: 36px;
  border: 2px solid #fff;
  background-color: #ffba26;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  position: relative;
  background-clip: padding-box;
  color: #fff;
}

.dl-step .contentInfo {
  margin-left: 70px;
  border-radius: 4px;
  padding: 8px 13px 13px;
  position: relative;
}

.dl-step-1 {
  margin-bottom: 60px;
  position: relative;
}

.dl-step-1 .contentInfo {
  background-color: #ffba26;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  font-size: 15px;
  color: #fff;
  padding: 0 13px;
  height: 38px;
  line-height: 38px;
}

.dl-step-1:before {
  content: "";
  display: block;
  width: 89px;
  height: 75px;
  position: absolute;
  right: 0;
  top: -56px;
  background-size: auto 213px;
  background-repeat: no-repeat;
  background-position: 0 0;
  z-index: 10;
}

.dl-step-1 .index:before {
  content: "";
  display: block;
  height: 45px;
  width: 2px;
  background-color: #fff;
  position: absolute;
  top: 45px;
  left: 17px;
  border-radius: 2px;
}

.dl-step-2 {
  margin-bottom: 58px;
}

.dl-step-2 .index,
.dl-step-3 .index {
  /*background-color: rgba(39, 41, 57, 0.2);*/
}

.dl-step-2 .contentInfo,
.dl-step-3 .contentInfo {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.dl-step-2 .contentInfo .tip,
.dl-step-3 .contentInfo .tip {
  margin-bottom: 2px;
  font-size: 15px;
  color: #333;
  font-family: Microsoft Yahei;
}

.dl-step-2 .index:before {
  content: "";
  display: block;
  height: 128px;
  width: 2px;
  background-color: #fff;
  position: absolute;
  top: 45px;
  left: 17px;
  border-radius: 2px;
}

.dl-devices {
  overflow: hidden;
}

.dl-devices .item {
  background-color: #ceecfb;
  width: 49%;
  float: left;
  padding: 5px 0;
}

.dl-devices .android {
  margin-left: 1px;
}

.dl-devices .name {
  float: left;
  background-color: #fff;
  padding: 0 4px;
  color: #666;
  line-height: 14px;
  font-size: 12px;
  font-family: Microsoft Yahei;
}

.dl-devices .icon {
  height: 40px;
  clear: left;
  background-size: auto 213px;
  background-repeat: no-repeat;
}

.dl-devices .desc {
  color: #666;
  font-size: 10px;
  text-align: center;
  font-family: Microsoft Yahei;
}

.dl-devices .ios .icon {
  background-position: center -85px;
}

.dl-devices .android .icon {
  background-position: center -131px;
}

.dl-step-3 .app {
  background-color: #ceecfb;
  padding: 8px 25px;
}

.dl-step-3 .app .icon {
  padding-left: 38px;
  height: 31px;
  line-height: 31px;
  background-repeat: no-repeat;
  background-position: -29px -182px;
  background-size: auto 213px;
  font-size: 10px;
  color: #666;
  font-family: Microsoft Yahei;
}

.dl-step .contentInfo:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  border: 5px solid transparent;
  border-right-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 14px;
  left: -11px;
}

.dl-step .contentInfo:after {
  content: "";
  display: block;
  height: 0;
  width: 0;
  border: 5px solid transparent;
  position: absolute;
  top: 13px;
  left: -10px;
}

.dl-step-1 .contentInfo:after {
  border-right-color: #ffba26;
}

.dl-step-2 .contentInfo:after,
.dl-step-3 .contentInfo:after {
  border-right-color: rgba(255, 255, 255, 0.9);
}
</style>